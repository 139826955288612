import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ADD_ADFOX_IP_V6 } from '../constants/index';
import { isHealthPath } from '../router/utils';
import PageScroll from '~/libs/PageScroll';
import { allSettled } from '~/libs/allSettled';
import {
  FETCH_WIDGETS,
  FETCH_MENUS_IF_NEEDED,
  SHOW_NAV,
  HIDE_NAV,
  ADD_JOB,
  CLEAR_ALL_JOBS,
  UPDATE_USER,

  ADD_INTEGER_ID_USER,
  FETCH_USER,
  PATCH_USER_PROPERTY,
  DISABLE_SOCIAL_ACCOUNT,
  DELETE_USER_ACCOUNT,

  CLEAR_USER,
  UPDATE_WORDPRESS_AUTHENTICATION,
  SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS,
  UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS,
  HIDE_SAFARI_PUSH_DIALOG,
  SHOW_SAFARI_PUSH_DIALOG,
  UPDATE_IS_HEAD_SCRIPTS_LOADED,
  RE_FETCH_REACTIONS,
  FETCH_RECIPE_WIDGETS,
  UPDATE_USER_PROPERTY,
  FETCH_USER_LOCATION,
  LOGOUT,
  SET_USER_RT_ACTION,
  SET_USER_RT_MUTATION,
  GET_IS_SEND_REFRESH_TOKEN,
  TIMEOUT,
  UPDATE_IS_USER_LOADING,
  SET_AUTH_METHODS_RESTRICTED,
  CLEAR_USER_RELATED_DATA,
  CLEAR_FOLDERS,
  FETCH_HEALTH_WIDGETS,
} from '~/constants';
import { ACCESS_TOKEN_COOKIE_NAME, AUTH_API_URL, isDevelopment } from '~/constants/config';
import { isAnyRecipePage, isAmpPage } from '~/router';
import { clearUserToken, getAccessToken, getSubId } from '~/utils';
import { FETCH_MASCOT } from '~/constants/mascot';
import { COMMON_RECIPE_WIDGETS, COMMON_WIDGETS, AMP_WIDGETS, HEALTH_MAIN_WIDGETS } from '~/constants/widgets';

// https://vuex.vuejs.org/ru/guide/strict.html
export const strict = isDevelopment;

export const state = (): Omit<IRootState, 'route'> => ({
  visibleModalWindows: [],
  jobs: [],
  user: null,
  isUserLoading: false,
  userIntegerId: null,
  isAuthenticatedInWordpress: false,
  isSubscribedToWebPushNotifications: false,
  isHeadScriptsLoaded: false,
  authMethodsRestricted: null,
  isSendRefreshToken: false,
});

export const mutations: MutationTree<IRootState> = {
  [SHOW_NAV] (state) {
    // убираем все модальные окна, кроме 'search'
    state.visibleModalWindows = state.visibleModalWindows.filter((visible: string) => visible === 'search');
    state.visibleModalWindows.push('nav');
    PageScroll.disable();
  },
  [HIDE_SAFARI_PUSH_DIALOG] (state) {
    state.visibleModalWindows = state.visibleModalWindows.filter((visible: string) => visible !== 'safari-push-dialog');
    PageScroll.enable();
  },
  [SHOW_SAFARI_PUSH_DIALOG] (state) {
    state.visibleModalWindows = ['safari-push-dialog'];
    PageScroll.disable();
  },
  [HIDE_NAV] (state) {
    state.visibleModalWindows = state.visibleModalWindows.filter((visible: string) => visible !== 'nav');
    PageScroll.enable();
  },
  [ADD_JOB] (state, { job }) {
    state.jobs = [
      ...state.jobs,
      job,
    ];
  },
  [CLEAR_ALL_JOBS] (state) {
    state.jobs = [];
  },
  [UPDATE_USER] (state, { user }) {
    state.user = Object.seal(user);
  },
  [UPDATE_IS_USER_LOADING] (state, isLoading: boolean) {
    state.isUserLoading = isLoading;
  },
  [UPDATE_USER_PROPERTY] (state, payload) {
    // @ts-ignore
    state.user[payload.property] = payload.value;
  },
  [CLEAR_USER] (state) {
    state.user = null;
    state.userIntegerId = null;
  },
  [UPDATE_WORDPRESS_AUTHENTICATION] (state, { newState }) {
    state.isAuthenticatedInWordpress = newState;
  },
  [SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS] (state) {
    state.isSubscribedToWebPushNotifications = true;
  },
  [UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS] (state) {
    state.isSubscribedToWebPushNotifications = false;
  },
  [UPDATE_IS_HEAD_SCRIPTS_LOADED] (state, { isHeadScriptsLoaded }) {
    state.isHeadScriptsLoaded = isHeadScriptsLoaded;
  },
  [ADD_INTEGER_ID_USER] (state, { subId }) {
    state.userIntegerId = subId;
  },
  [SET_USER_RT_MUTATION] (state, isSend: boolean) {
    state.isSendRefreshToken = isSend;
  },
  [SET_AUTH_METHODS_RESTRICTED] (state, isRestricted) {
    state.authMethodsRestricted = isRestricted;
  },
};

export const actions: ActionTree<IRootState, IRootState> = {
  nuxtServerInit ({ dispatch, commit }, rootState) {
    const ip = (
      rootState.req.headers['x-forwarded-for'] ||
      rootState.req.socket.remoteAddress || ''
    );
    commit(ADD_ADFOX_IP_V6, ip);

    const isRecipes = isAnyRecipePage(rootState.route.name as string | null | undefined);
    const isHealth = isHealthPath(rootState.route.path as string | null | undefined);
    const isAmp = isAmpPage(rootState.route.name);
    return allSettled([
      isRecipes
        ? dispatch(FETCH_RECIPE_WIDGETS, COMMON_RECIPE_WIDGETS)
        : isHealth
          ? dispatch(FETCH_HEALTH_WIDGETS, HEALTH_MAIN_WIDGETS)
          : dispatch(FETCH_WIDGETS, isAmp ? AMP_WIDGETS : COMMON_WIDGETS),
      ...(isAmp ? [] : [dispatch(FETCH_MASCOT)]),
      dispatch(FETCH_MENUS_IF_NEEDED),
    ]);
  },

  nuxtClientInit () {
  },

  async [FETCH_USER] ({ commit, dispatch }, { subId, createAxiosInstance = false }) {
    const accessToken = getAccessToken();
    commit(UPDATE_IS_USER_LOADING, true);

    if (createAxiosInstance) {
      const url = `/api/v1/users/${subId}`;

      const axiosInstance = axios.create({
        baseURL: `${AUTH_API_URL}`,
        timeout: TIMEOUT,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-auth-app': 'web',
        },
      });

      const response = await axiosInstance.get(url);
      await dispatch(UPDATE_USER, { user: response.data.data });
      commit(UPDATE_IS_USER_LOADING, false);
      return response;
    }

    const userData = await this.$authApi.getUserData(accessToken, subId);
    await dispatch(UPDATE_USER, { user: userData.data.data });
    commit(UPDATE_IS_USER_LOADING, false);
    return userData;
  },

  async [FETCH_USER_LOCATION] ({ commit }) {
    const { data } = await this.$authApi.fetchUserLocation();

    commit(SET_AUTH_METHODS_RESTRICTED, data.isRestricted);
  },

  [SET_USER_RT_ACTION] ({ commit }, isSend: boolean) {
    commit(SET_USER_RT_MUTATION, isSend);
  },

  [LOGOUT] ({ dispatch, commit }) {
    this.$authApi.logout();

    clearUserToken();
    dispatch(CLEAR_USER);
    commit(CLEAR_USER_RELATED_DATA);
    commit(CLEAR_FOLDERS);
    dispatch('talker/user/CLEAR_USER_DATA_ACTION');
  },

  async [UPDATE_USER] ({ commit, dispatch }, payload) {
    commit(UPDATE_USER, payload);
    commit(ADD_INTEGER_ID_USER, { subId: payload.user.integer_id });
    await dispatch(RE_FETCH_REACTIONS);
  },
  [CLEAR_USER] ({ commit, dispatch }) {
    commit(CLEAR_USER);
    dispatch(RE_FETCH_REACTIONS);
  },

  /* eslint-disable-next-line */
  [PATCH_USER_PROPERTY] ({}, payload) {
    const subId = getSubId();

    if (subId) {
      return this.$authApi.patchUserProperty(subId, payload)
        .then((resp: any) => {
          if (resp.status === 200) {
            this.commit(UPDATE_USER_PROPERTY, payload);
            return Promise.resolve(resp);
          }
        }).catch((error) => {
          return Promise.reject(error);
        });
    }
  },

  /* eslint-disable-next-line */
  [DISABLE_SOCIAL_ACCOUNT] ({}, payload) {
    const subId = getSubId();

    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;
      return this.$authApi.removeUserSocialAccount(accessToken, subId, payload);
    }
  },

  /* eslint-disable-next-line */
  [DELETE_USER_ACCOUNT] ({}, payload) {
    const subId = getSubId();

    if (subId) {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) as string;
      return this.$authApi.removeUserAccount(accessToken, subId, payload);
    }
  },

};

export const getters: GetterTree<IRootState, IRootState> = {
  [GET_IS_SEND_REFRESH_TOKEN]: (state: IRootState) => {
    return state.isSendRefreshToken;
  },
};
